<div class="container mt-4">
  @if (!recetteouts){
  <div class="row">
    <div class="col-12">
      <h3 class="text-center"><fa-icon [icon]="faUtensils" class="pe-2"></fa-icon>Que souhaitez-vous manger ?</h3>
    </div>
  </div>
  }

  @if (!recetteouts){
  <div class="row mt-2">
    <div class="col-12">
      <p><fa-icon [icon]="faCamera" class="pe-2"></fa-icon>Donnez vos choix d'ingrédients à partir d'une photo de votre réfrigérateur ou de votre placard</p>
    </div>
    <div class="col-12 text-center">
      <input type="file" id="inputImage" class="form-control" (change)="onFileSelected($event)" accept="image/*" capture="environment" />
    </div>
    <div class="col-12 text-center mt-2">
      @if (!analysis){
        <button class="btn btn-secondary" (click)="onUpload()" [disabled]="!base64String"><fa-icon [icon]="faUpload" class="pe-2"></fa-icon>Extraire les ingrédients</button>
      } @else {
        <div class="alert alert-primary" role="alert">
          Analyse de l'image en cours...
        </div>
      }
    </div>

    <div class="col-12 mt-2">
      <p><fa-icon [icon]="faCommentDots" class="pe-2"></fa-icon>Ou exprimez vos choix ci-dessous de façon naturelle sans oublier de préciser les aliments que vous n'aimez pas</p>

      <!-- display exemplesPrompt with a button that add the exemple in the chat textarea -->
      <div class="col-12 mt-2 fst-italic">
        <ul class="list-unstyled">
          <li>Exemples :</li>
          @for (exemple of exemplesPrompt; track exemple) {
            <ul>{{ exemple }}&nbsp;&nbsp;<button class="btn btn-info btn-sm" (click)="addExemple(exemple)"><fa-icon [icon]="faPlus"></fa-icon></button></ul>
          }
        </ul>
      </div>
    </div>
    <div class="col-12">
      <textarea class="form-control" id="chat" rows="3" [(ngModel)]="prompt"></textarea>
    </div>
  </div>
  }



  @if (!recetteouts){
  <div class="row mt-2">
    <div class="col-12 text-center mt-2">
      @if (!search){
        <button class="btn btn-primary" (click)="searchRecipe()" [disabled]="!prompt"><fa-icon [icon]="faSearch"></fa-icon>&nbsp;Rechercher une recette</button>
      } @else {
        <div class="alert alert-primary" role="alert">
          Recherche en cours...
        </div>
      }
    </div>
  </div>
  }

  @if (search || analysis){
    <div class="row mt-1 text-center">
      <div class="col-12">
        <img ngSrc="images/loading.gif" width="300" height="300" alt="loading">
      </div>
    </div>
  }

  @if (recetteouts && !search){
  <div class="row mt-4 mb-2">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <h3>
          <fa-icon [icon]="faUtensils" class="pe-2"></fa-icon>
          @if(recetteouts){
            @if(recetteouts.length > 1){
              Nous vous proposons les recettes suivantes :
            } @else if (recetteouts.length == 1){
              Nous vous proposons la recette suivante :
            } @else if (recetteouts.length == 0){
              Aucune recette n'a été trouvée. Vous pouvez vous adresser à un professionnel de la diététique.
            }
          }
        </h3>
      </div>

      <div class="accordion accordion-flush mb-2" id="recetteAccordion">
        @for (recetteout of recetteouts; track recetteout; let i = $index) {
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading{{i}}">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="true" [attr.aria-controls]="'collapse' + i">
                {{ recetteouts[i].titre }}
              </button>
            </h2>
            <div id="collapse{{i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#recetteAccordion">
              <div class="accordion-body">
                <button class="btn btn-primary mb-3" (click)="addToFavorites(recetteouts[i])">
                  @if (recetteouts[i].isfavorite) {
                    <fa-icon [icon]="faHeart"></fa-icon>
                  } @else {
                    <fa-icon [icon]="faHeartRegular"></fa-icon>
                  }
                </button>

                <h4><fa-icon [icon]="faCarrot" class="pe-2"></fa-icon>Ingrédients :</h4>
                <ul>
                  @for (ingre of recetteouts[i].ingredientslist; track ingre + $index) {
                    <li>{{ ingre }}</li>
                  }
                </ul>
                <p>{{recetteouts[i].ingredients}}</p>

                <h4><fa-icon [icon]="faKitchenSet" class="pe-2"></fa-icon>Préparation :</h4>
                <p>{{ recetteouts[i].preparation }}</p>

                <h4><fa-icon [icon]="faListCheck" class="pe-2"></fa-icon>Les étapes</h4>
                <ul>
                  @for (step of recetteouts[i].steps; track step) {
                    <li>{{ step }}</li>
                  }
                </ul>
              </div>
            </div>
          </div>
        }
      </div>
      <button class="btn btn-primary" (click)="reset()"><fa-icon [icon]="faRedo"></fa-icon>&nbsp;Nouvelle recherche</button>
    </div>
  </div>
  }

  <!-- display errorMessage -->
  @if (errorMessage){
    <div class="row mt-4">
      <div class="col-12">
        <div class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
        <button class="btn btn-primary" (click)="reset()"><fa-icon [icon]="faRedo"></fa-icon>&nbsp;Nouvelle recherche</button>
      </div>
    </div>
  }
</div>
