import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Recetteout} from "../models/recetteout";
import {catchError, Observable, throwError} from "rxjs";
import {Recettein} from "../models/recettein";

@Injectable({
  providedIn: 'root'
})
export class RecetteService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  recette(recettein: Recettein): Observable<Recetteout[]> {
    return this.http.post<Recetteout[]>(`${this.apiUrl}/recette`, recettein).pipe(
       catchError((error: HttpErrorResponse) => {
         return throwError(() =>error);
       })
    );
  }
}
