import { Component, inject } from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Recettein} from "../models/recettein";
import {RecetteService} from "../services/recette.service";
import {Recetteout} from "../models/recetteout";
import {NgOptimizedImage} from "@angular/common";
import {IngredientsService} from "../services/ingredients.service";
import {Ingredientin} from "../models/ingredientin";
import {
  faCamera, faCarrot,
  faCommentDots, faHeart, faKitchenSet, faListCheck,
  faRedo,
  faSearch,
  faUpload,
  faUtensils,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

import {
  faHeart as faHeartRegular
} from "@fortawesome/free-regular-svg-icons";

import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FavorisService} from "../services/favoris.service";
import {Favoriin} from "../models/favoriin";

@Component({
  selector: 'app-chatbot',
  standalone: true,
  imports: [
    FormsModule,
    NgOptimizedImage,
    FaIconComponent
  ],
  templateUrl: './chatbot.component.html',
  styleUrl: './chatbot.component.css'
})
export class ChatbotComponent {

  iduser = 1; // iduser is hardcoded to 1
  analysis = false;
  search = false;
  prompt = '';
  selectedFile: File | null = null;
  base64String= '';
  recetteouts: Recetteout[] | null = null;
  errorMessage: string | null = null;

  exemplesPrompt = [
    "J’aime les tomates et les carottes mais je n’aime pas la salade ni l'aubergine",
    "J’aime les tomates et les carottes mais je suis allergique aux crustacés",
    "J’aime les tomates et les carottes mais je déteste le vinaigre et l’huile"];

  private recetteService = inject(RecetteService);
  private ingredientsService = inject(IngredientsService);
  private favorisService = inject(FavorisService);

  searchRecipe() {
    // call the recette service
    this.search = true;
    let recettein = new Recettein();
    recettein.prompt = this.prompt;
    recettein.iduser = this.iduser;
    this.recetteService.recette(recettein).subscribe({
      next: (data) => {
        this.recetteouts = data;
        console.log('Recetteout:', data);
        this.errorMessage = null;
        this.search = false;
      },
      error: (error) => {
        console.error('Error retrieving recette:', error);
        this.errorMessage = error.error.message;
        this.search = false;
      }
    });
    }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.base64String = e.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  onUpload(): void {
    this.analysis = true;
    if (this.base64String) {
      let ingredientin = new Ingredientin();
      ingredientin.base64 = this.base64String;
      this.ingredientsService.ingredients(ingredientin).subscribe({
        next: (data) => {
          console.log('Ingredients:', data);
          if(data.ingredientslist.length === 0) {
            this.errorMessage = "Aucun ingrédient trouvé";
          }else {
            this.prompt = "je souhaite les ingrédients suivants : ";

            // concat ingredients with a comma
            this.prompt += data.ingredientslist.join(', ');
            this.prompt = this.prompt.toLowerCase();
          }
          this.analysis = false;
        },
        error: (error) => {
          console.error('Error retrieving ingredients:', error);
          this.analysis = false;
        }
      });
      }
  }

  reset() {
    this.prompt = '';
    this.selectedFile = null;
    // reset html element inputImage
    const inputImage = document.getElementById('inputImage') as HTMLInputElement;
    if(inputImage){
      inputImage.value = '';
    }

    this.base64String = '';
    this.recetteouts = null;
    this.errorMessage = null;
  }

  protected readonly faCamera = faCamera;
  protected readonly faUtensils = faUtensils;
  protected readonly faCommentDots = faCommentDots;
  protected readonly faUpload = faUpload;
  protected readonly faSearch = faSearch;
  protected readonly faRedo = faRedo;
  protected readonly faCarrot = faCarrot;
  protected readonly faKitchenSet = faKitchenSet;
  protected readonly faListCheck = faListCheck;
  protected readonly faHeart = faHeart;

  addToFavorites(recipe: Recetteout) {
    let favoriin = new Favoriin();
    favoriin.idrecipe = recipe.id;
    favoriin.iduser = this.iduser;
    if(recipe.isfavorite) {
      favoriin.operation = 'REMOVE';
    }else{
      favoriin.operation = 'ADD';
    }
    this.favorisService.favoris(favoriin).subscribe({
      next: (data) => {
        recipe.isfavorite = !recipe.isfavorite;
        this.errorMessage = null;
      },
      error: (error) => {
        console.error('Error adding to favorites:', error);
        this.errorMessage = error.error.message;
      }
    });
  }

  addExemple(exemple: string) {
    this.prompt = exemple;
  }

  protected readonly faPlus = faPlus;
  protected readonly faHeartRegular = faHeartRegular;
}
