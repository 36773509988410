import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Ingredientin} from "../models/ingredientin";
import {Observable} from "rxjs";
import {Ingredientout} from "../models/ingredientout";
import {Favoriout} from "../models/favoriout";
import {Favoriin} from "../models/favoriin";

@Injectable({
  providedIn: 'root'
})
export class FavorisService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  favoris(favoriin: Favoriin): Observable<Favoriout> {
    return this.http.post<Favoriout>(`${this.apiUrl}/favoris`, favoriin);
  }
}
