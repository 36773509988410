import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Recettein} from "../models/recettein";
import {Observable} from "rxjs";
import {Recetteout} from "../models/recetteout";
import {Ingredientin} from "../models/ingredientin";
import {Ingredientout} from "../models/ingredientout";

@Injectable({
  providedIn: 'root'
})
export class IngredientsService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  ingredients(ingredientin: Ingredientin): Observable<Ingredientout> {
    return this.http.post<Ingredientout>(`${this.apiUrl}/ingredients`, ingredientin);
  }
}
