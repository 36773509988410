<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand d-flex align-items-center ms-2" href="#">
    <img src="images/logo-sc.png" width="50" height="50" alt="Logo" class="me-1">
    <span class="navbar-text d-lg-inline"><span class="fs-6">Agent diététique</span>
      @if (currentUrl.includes('dev') || currentUrl.includes('localhost')){
      <span class="badge bg-danger ms-1">dev</span>
      } @else {
      <span class="badge bg-warning ms-1">démo</span>
      }
    </span>
  </a>
  <button class="navbar-toggler me-2" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-center me-2" id="navbarNav">
    <span class="navbar-text d-lg-none">Agent diététique - Démo</span>
  </div>
</nav>

